import { ChangeEvent, KeyboardEvent } from "react";

interface UseSearchProps {
    onChange: (value: string) => void;
    submitSearch: (value?: string) => void;
}

export const useSearch = ({ onChange, submitSearch }: UseSearchProps) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event?.target) return;

        onChange(event.target.value);
    };

    const clearSearchResults = () => {
        onChange("");
        submitSearch("");
    };

    const handleClear = () => {
        clearSearchResults();
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            submitSearch();
        }
    };

    const handleSuggestionClick = (item: string) => {
        if (!item) return;

        onChange(item);
        submitSearch(item);
    };

    return {
        handleChange,
        handleClear,
        handleKeyDown,
        handleSuggestionClick,
    };
};
