import { Icon, Palette, Stack, Text, Widget } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switcher } from "src/components/shared/Switcher";
import styled from "styled-components";

import { WIDGET_VIEW_MODE_OPTIONS } from "../Summary.constants";
import { WidgetViewMode } from "../Summary.types";

const Bar = styled(Text).attrs({
    color: "secondary",
    micro: true,
})`
    display: flex;
    gap: 2px;
    align-items: center;
    width: 32px;
    padding-left: 6px;

    &::after {
        content: "";
        display: block;
        width: 10px;
        height: 14px;
        border-radius: 0 2px 2px 0;
        background-color: ${Palette.Navy100};
    }
`;

export const SiteEventsWidgetStatic = () => {
    const { t } = useTranslation();
    const [viewMode, setViewMode] = useState<WidgetViewMode>("most");
    const handleSetViewMode = (mode: WidgetViewMode) => {
        setViewMode(mode);
    };

    return (
        <Widget
            data-testid="site-events-widget"
            title={t("insights.summary.siteEventsWidget.title")}
            tooltip={t(`insights.summary.siteEventsWidget.tooltip.${viewMode}`)}
            headerActions={<Switcher options={WIDGET_VIEW_MODE_OPTIONS} onChange={handleSetViewMode} />}
        >
            <Stack direction="column" flex={1} gap="XS">
                <Stack mt="XXS" alignItems="center" gap="XS" justifyContent="flex-end">
                    <Text bold color="secondary">
                        {t("filters.allCategories")}
                    </Text>
                    <Icon variant="ArrowDown" color="secondary" />
                </Stack>
                <Stack paddingVertical="S" direction="column" gap="L">
                    <Bar>A</Bar>
                    <Bar>B</Bar>
                    <Bar>C</Bar>
                    <Bar>D</Bar>
                    <Bar>E</Bar>
                </Stack>
            </Stack>
        </Widget>
    );
};
