import { Spacing, Theme } from "@secuis/ccp-react-components";
import { Responsive, WidthProvider } from "react-grid-layout";
import styled from "styled-components";

export const ResponsiveGridStyled = styled(WidthProvider(Responsive)).attrs({
    isResizable: false,
    preventCollision: false,
    allowOverlap: false,
    margin: [Spacing.S, Spacing.S],
    containerPadding: [0, 0],
    compactType: "vertical",
    resizeHandles: [],
})`
    .react-grid-item.react-grid-placeholder {
        background: ${Theme.tertiary};
    }
`;
