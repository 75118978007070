import { Dictionary } from "lodash";
import { Layout } from "react-grid-layout";

import { ColumnsPerBreakpoint, GridItem } from "./DraggableGrid.types";
import { DraggableWidgetWrapper } from "./DraggableWidgetWrapper";
import { ResponsiveGridStyled } from "./shared/ResponsiveGridStyled.styles";

type Props = {
    layout: Layout[];
    widgetsMap: Dictionary<GridItem>;
    rowHeight: number;
    breakpoints: ColumnsPerBreakpoint;
    columns: ColumnsPerBreakpoint;
    handleAddWidget: (id: string) => void;
    editMode: boolean;
};

export const RemovedWidgetsGrid = ({ layout, widgetsMap, rowHeight, breakpoints, columns, handleAddWidget, editMode }: Props) => {
    return (
        <ResponsiveGridStyled layouts={{ lg: layout }} isDraggable={false} rowHeight={rowHeight} breakpoints={breakpoints} cols={columns}>
            {layout?.map(({ i }) => {
                const Content = widgetsMap[i]?.Placeholder;

                return (
                    Content && (
                        <DraggableWidgetWrapper widgetId={i} key={i} onAdd={handleAddWidget} editMode={editMode} isDisabled>
                            <Content />
                        </DraggableWidgetWrapper>
                    )
                );
            })}
        </ResponsiveGridStyled>
    );
};
