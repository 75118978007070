import { CategoryLevel1Keys } from "../../../../models/ReportCategoryModel";

export const SUMMARY_EVENT_CATEGORIES = [
    CategoryLevel1Keys.criminalActOrSuspiciousBehavior,
    CategoryLevel1Keys.facility,
    CategoryLevel1Keys.healthAndSafety,
    CategoryLevel1Keys.peopleAndAssets,
];

// NOTE: workaround for fixing resized graphs when edit mode toggle
// jira issue: https://secsisip.atlassian.net/browse/GLOB-5439
// all usages of these variables can be removed after request caching will be implemented and Graphs will be moved to @ccp
export const DRAGGABLE_GRAPH_MAX_HEIGHT = 440;
export const DRAGGABLE_GRAPH_MAX_HEIGHT_WITHOUT_LEGEND = 520;
