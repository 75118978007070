import { Breakpoints, Headline, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { QaProps, StyleProps } from "../../../../models";
import { Widget } from "../shared/components";
import { DRAGGABLE_GRAPH_MAX_HEIGHT_WITHOUT_LEGEND } from "../shared/constants";
import { DeviantHourTooltipContent } from "./DeviantHourTooltipContent";
import { formatXAxisTick } from "./DeviantHourWidget.helpers";
import { useDeviantHourWidget } from "./DeviantHourWidget.hooks";
import { LineChart } from "./LineChart";

type Props = QaProps & StyleProps;

export const DeviantHourWidget = (props: Props) => {
    const { t } = useTranslation();
    const showAllTicks = !useHasMaxWidth(Breakpoints.L);
    const { chartData, topHourLabel, isLoading, isEmpty, xAxisTicks } = useDeviantHourWidget();
    const isCustomizable = useFeatureFlag(featureFlags.personalizeKpiSummary);

    return (
        <Widget
            {...props}
            tooltip={t("insights.summary.deviantHourWidget.tooltip")}
            title={t("insights.summary.deviantHourWidget.title")}
            loading={isLoading}
            empty={isEmpty}
            subtitle={
                <Headline bold color="secondary">
                    {topHourLabel}
                </Headline>
            }
        >
            <LineChart
                style={isCustomizable ? { height: `${DRAGGABLE_GRAPH_MAX_HEIGHT_WITHOUT_LEGEND}px` } : null}
                data={chartData}
                TooltipContent={DeviantHourTooltipContent}
                xAxisProps={{
                    ticks: xAxisTicks,
                    tickFormatter: formatXAxisTick,
                    interval: showAllTicks ? 0 : undefined,
                }}
            />
        </Widget>
    );
};
