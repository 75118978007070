import { Breakpoints, Separator, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Loading } from "src/components/shared/Loading";
import { MixpanelEvent } from "src/models/tracking/mixpanelEvents";
import TrackingActions from "src/store/tracking/TrackingActions";

import { useClientSeverityLevel } from "./ClientSeverityLevel.hooks";
import { ColumnsWrapperStyled, WrapperStyled } from "./ClientSeverityLevel.styles";
import { CategoriesCount, ClientSeverityTable, PageHeadline, ScreenSizeNotSupported, Search, SeverityLevelInfo } from "./components";
import { DescriptionSection } from "./components/CollapsibleSections";

export const ClientSeverityLevel = () => {
    const { t } = useTranslation();
    const { tableData, isEditable, onSeverityChange, isLoading, searchValue, setSearchValue, search, suggestions } = useClientSeverityLevel();
    const showTwoColumns = !useHasMaxWidth(Breakpoints.L);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(TrackingActions.trackEvent(MixpanelEvent.SeverityLevelsTabView));
    }, [dispatch]);

    return (
        <WrapperStyled>
            <Separator />
            <PageHeadline />
            {isMobile ? (
                <ScreenSizeNotSupported />
            ) : (
                <>
                    <Search
                        value={searchValue}
                        onChange={setSearchValue}
                        submitSearch={search}
                        suggestions={suggestions}
                        placeholder={t("settings.clientSeverityLevel.Search.Placeholder")}
                    />
                    <DescriptionSection />
                    <CategoriesCount count={tableData?.length} />
                    <ColumnsWrapperStyled showTwoColumns={showTwoColumns}>
                        {isLoading ? <Loading /> : <ClientSeverityTable data={tableData} isEditable={isEditable} onSeverityChange={onSeverityChange} />}
                        <SeverityLevelInfo />
                    </ColumnsWrapperStyled>
                </>
            )}
        </WrapperStyled>
    );
};
