import { keyBy } from "lodash";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { ItemCallback, Layout, Layouts } from "react-grid-layout";

import { GRID_BREAKPOINTS, GRID_ROW_SIZE } from "./DraggableGrid.constants";
import {
    calculateNewWidgetPosition,
    getColumnsSetup,
    getCurrentLayoutBreakpoint,
    prepareResponsiveLayoutConfig,
    updateRemovedLayout,
} from "./DraggableGrid.helpers";
import { DraggableGridProps, ScreenWidthVariant } from "./DraggableGrid.types";

export const useDraggableGrid = ({
    defaultLayouts,
    defaultLayoutConfig,
    widgets,
    isEditable,
    onLayoutChange,
    heightUnit = GRID_ROW_SIZE,
    ...props
}: DraggableGridProps) => {
    const columns = useMemo(() => getColumnsSetup(props.columns), [props.columns]);
    const layouts = useMemo(
        () => defaultLayouts || prepareResponsiveLayoutConfig(widgets, defaultLayoutConfig),
        [defaultLayoutConfig, defaultLayouts, widgets],
    );
    const widgetsMap = useMemo(() => keyBy(widgets, "id"), [widgets]);
    const [breakpoint, setBreakpoint] = useState<ScreenWidthVariant>("lg");
    const [draggedId, setDraggedId] = useState<null | string>(null);
    const [addedWidgets, setAddedWidgets] = useState<string[]>([]);
    const [removedLayout, setRemovedLayout] = useState<Layout[]>([]);

    useEffect(() => {
        setRemovedLayout(updateRemovedLayout(layouts[breakpoint], columns, breakpoint));
    }, [layouts, columns, breakpoint]);

    const handleLayoutChange = useCallback(
        (layout: Layout[], layouts: Layouts) => {
            if (isEditable) {
                if (draggedId) {
                    onLayoutChange?.(layouts);
                }
            }
            setBreakpoint(getCurrentLayoutBreakpoint());
        },
        [isEditable, draggedId, onLayoutChange],
    );

    useEffect(() => {
        if (!isEditable) {
            setAddedWidgets([]);
        }
    }, [isEditable]);

    const handleDragStart: ItemCallback = (_, oldItem) => {
        setDraggedId(oldItem.i);
    };
    const handleDragStop: ItemCallback = () => {
        setDraggedId(null);
    };

    const handleRemoveWidget = useCallback(
        (widgetId: string) => {
            const newLayouts = Object.keys(layouts).reduce((acc, key) => {
                const newLayout = layouts[key].filter((gridItem) => gridItem.i !== widgetId);
                return { ...acc, [key]: newLayout };
            }, {});
            onLayoutChange?.(newLayouts);
            setAddedWidgets(addedWidgets.filter((w) => w !== widgetId));
        },
        [layouts, addedWidgets, onLayoutChange],
    );

    const handleAddWidget = useCallback(
        (widgetId: string) => {
            const addedItem = removedLayout.find((gridItem) => gridItem.i === widgetId);
            const newLayouts: Layouts = Object.keys(layouts).reduce((updatedLayouts, screenWidthVariant) => {
                const newItemPosition = calculateNewWidgetPosition(layouts[screenWidthVariant], addedItem, columns[screenWidthVariant]);
                const newLayout = [...layouts[screenWidthVariant], { ...addedItem, ...newItemPosition }];
                return { ...updatedLayouts, [screenWidthVariant]: newLayout };
            }, {} as Layouts);

            onLayoutChange?.(newLayouts);
            setAddedWidgets([...addedWidgets, addedItem.i]);
        },
        [removedLayout, layouts, columns, addedWidgets, onLayoutChange],
    );

    const currentLayout = useMemo(() => layouts[breakpoint], [layouts, breakpoint]);

    return {
        columns,
        layouts,
        currentLayout,
        removedLayout,
        isEditable,
        handleLayoutChange,
        handleDragStart,
        handleDragStop,
        handleRemoveWidget,
        handleAddWidget,
        draggedId,
        widgetsMap,
        rowHeight: heightUnit,
        breakpoints: GRID_BREAKPOINTS,
        addedWidgets,
    };
};
