import { type ClientSeverityTableData, SeverityLevel } from "./ClientSeverityLevel.types";

export const SEVERITY_LEVELS: SeverityLevel[] = [SeverityLevel.Severe, SeverityLevel.High, SeverityLevel.Moderate, SeverityLevel.Low, SeverityLevel.Info];

export const SEVERITY_DESCRIPTIONS = [
    {
        severityLevel: SeverityLevel.Severe,
        description: "severityLevel.description.severe",
    },
    {
        severityLevel: SeverityLevel.High,
        description: "severityLevel.description.high",
    },
    {
        severityLevel: SeverityLevel.Moderate,
        description: "severityLevel.description.moderate",
    },
    {
        severityLevel: SeverityLevel.Low,
        description: "severityLevel.description.low",
    },
    {
        severityLevel: SeverityLevel.Info,
        description: "severityLevel.description.info",
    },
];

export const MOCK_TABLE_DATA: ClientSeverityTableData = [
    {
        category: {
            level1: "criminal_act_or_suspicious_behavior",
            level2: "cas_abuse",
            level3: "cas_domestic_violence",
        },
        severity: 0,
        defaultSeverity: 0,
    },
    {
        category: {
            level1: "criminal_act_or_suspicious_behavior",
            level2: "cas_abuse",
            level3: "cas_domestic_violence",
        },
        severity: 1,
        defaultSeverity: 0,
    },
    {
        category: {
            level1: "facility",
            level2: "fac_doors_windows",
            level3: "fac_doors_windows_damaged",
        },
        severity: 2,
        defaultSeverity: 0,
    },
    {
        category: {
            level1: "health_and_safety",
            level2: "has_active_hazard",
            level3: "has_explosion",
        },
        severity: 3,
        defaultSeverity: 3,
    },
    {
        category: {
            level1: "facility",
            level2: "fac_doors_windows",
            level3: "fac_doors_windows_damaged",
        },
        severity: 4,
        defaultSeverity: 4,
    },
    {
        category: {
            level1: "criminal_act_or_suspicious_behavior",
            level2: "cas_abuse",
            level3: "cas_domestic_violence",
        },
        severity: 0,
        defaultSeverity: 3,
    },
    {
        category: {
            level1: "criminal_act_or_suspicious_behavior",
            level2: "cas_abuse",
            level3: "cas_domestic_violence",
        },
        severity: 1,
        defaultSeverity: 0,
    },
    {
        category: {
            level1: "facility",
            level2: "fac_doors_windows",
            level3: "fac_doors_windows_damaged",
        },
        severity: 2,
        defaultSeverity: 3,
    },
    {
        category: {
            level1: "health_and_safety",
            level2: "has_active_hazard",
            level3: "has_explosion",
        },
        severity: 3,
        defaultSeverity: 3,
    },
    {
        category: {
            level1: "facility",
            level2: "fac_doors_windows",
            level3: "fac_doors_windows_damaged",
        },
        severity: 4,
        defaultSeverity: 3,
    },
    {
        category: {
            level1: "criminal_act_or_suspicious_behavior",
            level2: "cas_abuse",
            level3: "cas_domestic_violence",
        },
        severity: 0,
        defaultSeverity: 3,
    },
    {
        category: {
            level1: "criminal_act_or_suspicious_behavior",
            level2: "cas_abuse",
            level3: "cas_domestic_violence",
        },
        severity: 1,
        defaultSeverity: 3,
    },
    {
        category: {
            level1: "facility",
            level2: "fac_doors_windows",
            level3: "fac_doors_windows_damaged",
        },
        severity: 2,
        defaultSeverity: 3,
    },
    {
        category: {
            level1: "health_and_safety",
            level2: "has_active_hazard",
            level3: "has_explosion",
        },
        severity: 3,
        defaultSeverity: 2,
    },
    {
        category: {
            level1: "facility",
            level2: "fac_doors_windows",
            level3: "fac_doors_windows_damaged",
        },
        severity: 4,
        defaultSeverity: 4,
    },
];
