import { useTranslation } from "react-i18next";

import { Graph, GraphWrapperStyled, Widget } from "../shared/components";
import { STATIC_CHART_DATA, STATIC_MAX_VALUE } from "./DeviantDayWidget.constant";

export const DeviantDayWidgetStatic = () => {
    const { t } = useTranslation();

    return (
        <Widget title={t("insights.summary.deviantDay")} tooltip={t("insights.summary.deviantDayWidget.tooltip")}>
            <GraphWrapperStyled mt="M" isContentCenter>
                <Graph tickCount={3} data={STATIC_CHART_DATA} maxValue={STATIC_MAX_VALUE} barSize="sm" />
            </GraphWrapperStyled>
        </Widget>
    );
};
