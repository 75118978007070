import { Box, Breakpoints, EmptyState, Separator, Skeleton, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PatrolTourType } from "src/models/ReportModel";
import { TaskSchedule } from "src/store/schedule/types";

import { TranslatableEmptyInboxComponent } from "../shared/NoContent/EmptyInboxContent";
import { ScheduleTableStyled, SkeletonWrapperStyled, TableWrapperStyled } from "./Schedule.styles";
import { SORT_CONFIG } from "./ScheduleTable.constants";
import { getTableRowVariant } from "./ScheduleTable.helpers";
import { useTableColumns } from "./ScheduleTable.hooks";
import { ScheduleTableSortKey } from "./ScheduleTable.types";

const SMALL_SKELETON_HEIGHT = 48;
const SKELETON_HEIGHT = 64;
const SKELETON_MARGIN = 16;

type Props = {
    isLoading: boolean;
    isSuccess: boolean;
    tasks: (TaskSchedule & { children?: PatrolTourType[] })[];
    siteName: string;
};

export const ScheduleTable = ({ isLoading, isSuccess, tasks, siteName }: Props) => {
    const { t } = useTranslation();
    const isTablet = useHasMaxWidth(Breakpoints.M);
    const [skeletonsCount, setSkeletonsCount] = useState(6);
    const [sortKey, setSortKey] = useState<ScheduleTableSortKey>("startTime");
    const [sortedTasks, setSortedTasks] = useState<TaskSchedule[]>(tasks);
    const skeletonWrapperRef = useRef<HTMLDivElement>(null);
    const getSortByKeyFunc = (key: ScheduleTableSortKey) => () => {
        setSortKey(key);
        const sortFunction = SORT_CONFIG[key];
        setSortedTasks(sortFunction);
    };

    const { columns, childrenColumns } = useTableColumns(getSortByKeyFunc, sortKey, siteName);
    const renderSkeletons = useCallback((skeletonsCount: number) => {
        return new Array(skeletonsCount).fill(SKELETON_HEIGHT).map((height, index) => <Skeleton key={index} mode="rectangular" height={height} />);
    }, []);

    useLayoutEffect(() => {
        if (skeletonWrapperRef.current) {
            const wrapperHeight = skeletonWrapperRef.current.clientHeight;
            const count = (wrapperHeight - SMALL_SKELETON_HEIGHT - SKELETON_MARGIN) / (SKELETON_HEIGHT + SKELETON_MARGIN);
            setSkeletonsCount(parseInt(`${Math.ceil(count)}`));
        }
    }, [skeletonWrapperRef]);

    useEffect(() => {
        // update local tasks when tasks prop updated
        // should depend only on tasks, dont add sortKey to dependencies
        const sortFunction = SORT_CONFIG[sortKey];
        setSortedTasks(sortFunction(tasks));
    }, [tasks]);

    if (isTablet) {
        return (
            <>
                <Separator />
                <Stack direction="column" justifyContent="center" alignItems="center" mt="XL" mb="0" ml="0" mr="0">
                    <EmptyState
                        icon="WarningHigh"
                        title={t("schedule.table.unsupportedResolution.title")}
                        subtitle={t("schedule.table.unsupportedResolution.subtitle")}
                    />
                </Stack>
            </>
        );
    }

    if (isLoading) {
        return (
            <SkeletonWrapperStyled ref={skeletonWrapperRef} marginTop="S" direction="column" gap="XS">
                <Skeleton mode="rectangular" height={SMALL_SKELETON_HEIGHT} />
                {renderSkeletons(skeletonsCount)}
            </SkeletonWrapperStyled>
        );
    }

    if (!tasks.length && isSuccess) {
        return (
            <>
                <Separator />
                <Box paddingVertical="XS">
                    <TranslatableEmptyInboxComponent title={t("schedule.noDataToday.title")} subtitle={t("schedule.noDataToday.subtitle")} />
                </Box>
            </>
        );
    }

    return (
        <TableWrapperStyled>
            <ScheduleTableStyled isAllExpanded data={sortedTasks} columns={columns} childrenColumns={childrenColumns} getRowVariant={getTableRowVariant} />
        </TableWrapperStyled>
    );
};
