import { Breakpoints, Palette, useHasMaxWidth, Widget } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { QaProps } from "src/models";

import { formatXAxisTick } from "./DeviantHourWidget.helpers";
import { HourItem } from "./DeviantHourWidget.types";
import { LineChart, LineChartDataset } from "./LineChart";
import { Dot } from "./LineChart/Dot";

type Props = QaProps;

const placeholderData: HourItem[] = [...new Array(24)].map((_, hour) => {
    const hourKey = hour.toString().padStart(2, "0");

    return {
        key: hourKey,
        value: 0,
    };
});
const hoursChartTicks = placeholderData.map(({ key }) => key);

export const DeviantHourWidgetStatic = (props: Props) => {
    const { t } = useTranslation();
    const showAllTicks = !useHasMaxWidth(Breakpoints.L);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const xAxisTicks = isMobile ? hoursChartTicks.filter((_, index) => [0, 6, 12, 18, 23].includes(index)) : hoursChartTicks;
    const chartData: LineChartDataset[] = [
        {
            values: placeholderData,
            id: "totalEvents",
            highlights: [],
            customLineProps: {
                stroke: Palette.Navy100,
                dot: <Dot fill={Palette.Navy100} />,
                activeDot: <Dot fill={Palette.Navy100} />,
            },
        },
    ];

    return (
        <Widget {...props} tooltip={t("insights.summary.deviantHourWidget.tooltip")} title={t("insights.summary.deviantHourWidget.title")}>
            <LineChart
                data={chartData}
                xAxisProps={{
                    ticks: xAxisTicks,
                    tickFormatter: formatXAxisTick,
                    interval: showAllTicks ? 0 : undefined,
                }}
                yAxisProps={{
                    ticks: [0, 25, 50, 75],
                }}
                showTooltip={false}
            />
        </Widget>
    );
};
