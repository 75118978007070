import { Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const GraphWrapperStyled = styled(Stack).attrs({
    flex: 1,
    direction: "column",
    alignItems: "flex-start",
})<{ isContentCenter?: boolean; height?: number }>`
    max-width: 100%;
    height: ${({ height }) => height && `${height}px`};

    ${({ isContentCenter }) =>
        isContentCenter &&
        `
        flex-direction: row;
        align-items: center;
    `}
`;
