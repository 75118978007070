import { Widget } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Switcher } from "src/components/shared/Switcher";
import { QaProps } from "src/models";

import { Graph, GraphWrapperStyled } from "../shared/components";
import { WIDGET_VIEW_MODE_OPTIONS } from "../Summary.constants";
import { WidgetViewMode } from "../Summary.types";

type Props = QaProps;

const X_AXIS = ["A", "B", "C", "D", "E"];
const STATIC_CHART_DATA = X_AXIS.map((x) => ({
    key: x,
    label: x,
    name: x,
    value: 2,
}));

export const SiteReportsWidgetStatic = (props: Props) => {
    const { t } = useTranslation();
    const [viewMode, setViewMode] = useState<WidgetViewMode>("most");
    const handleSetViewMode = (mode: WidgetViewMode) => {
        setViewMode(mode);
    };

    return (
        <Widget
            {...props}
            title={t("insights.summary.siteReportsWidget.title")}
            tooltip={t(`insights.summary.siteReportsWidget.tooltip.${viewMode}`)}
            headerActions={<Switcher options={WIDGET_VIEW_MODE_OPTIONS} onChange={handleSetViewMode} />}
        >
            <GraphWrapperStyled mt="M" isContentCenter>
                <Graph tickCount={5} data={STATIC_CHART_DATA} maxValue={40} barSize="sm" />
            </GraphWrapperStyled>
        </Widget>
    );
};
