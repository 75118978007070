import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getSeverityLevelTranslationKey } from "src/helpers/SeverityLevelHelper";
import { useIsUserClientGuardingAdmin } from "src/store/clientSwitcher/ClientSwitcherHooks";
import { useReportCategories } from "src/store/reportCategories/ReportCategoriesHooks";
import { IReportCategoryLevel1 } from "src/store/reportCategories/types";
import { RequestStatus } from "src/store/RequestStatus";

import { SEVERITY_LEVELS } from "./ClientSeverityLevel.constants";
import { ClientSeverityTableData, SeverityLevel } from "./ClientSeverityLevel.types";

const SUGGESTION_LIMIT = 5;
const SUGGESTION_START_LENGTH = 3;

export const useClientSeverityLevel = () => {
    const { t } = useTranslation();
    const { reportCategories, fetchStatus } = useReportCategories();
    const [searchValue, setSearchValue] = useState("");
    const [allCategories, setAllCategories] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const isEditModeEnabled = false; // Edit Mode is not available in first release

    const search = (value?: string) => {
        const searchTerm = value ?? searchValue;
        const results = searchTerm.length
            ? allCategories.filter((row) => {
                  return `${row.name} ${getSeverityLevelTranslationKey(row.severity)}`.toLowerCase().includes(searchTerm.toLowerCase());
              })
            : allCategories;

        setSearchResults(results);
    };

    const suggestions = useMemo(() => {
        const severityLevels = SEVERITY_LEVELS.map((level) => t(getSeverityLevelTranslationKey(level)));

        if (searchValue.length < SUGGESTION_START_LENGTH) {
            return [];
        }

        const searchTerm = searchValue.toLowerCase();
        const categorySuggestions = allCategories.filter((row) => row.name.toLowerCase().includes(searchTerm)).map((row) => row.name);
        const severitySuggestions = severityLevels.filter((level) => level.toLowerCase().includes(searchTerm));

        return [...categorySuggestions, ...severitySuggestions].slice(0, SUGGESTION_LIMIT);
    }, [searchValue, allCategories, t]);

    const mapSeverityLevel = (severity: string): SeverityLevel => {
        if (severity in SeverityLevel) {
            return SeverityLevel[severity as keyof typeof SeverityLevel];
        }
    };

    const parseReportCategories = (data: IReportCategoryLevel1[]): ClientSeverityTableData => {
        return data.flatMap((level1Category) =>
            level1Category.level2Categories.flatMap((level2Category) =>
                level2Category.level3Categories.map((level3Category) => {
                    const severity = mapSeverityLevel(level3Category.severityLevel);

                    return {
                        name: `${t(`incident.category.${level1Category.key}`)} . ${t(`incident.category.${level2Category.key}`)} . ${t(
                            `incident.category.${level3Category.key}`,
                        )}`,
                        category: {
                            level1: level1Category.key,
                            level2: level2Category.key,
                            level3: level3Category.key,
                        },
                        severity: severity,
                        defaultSeverity: severity,
                    };
                }),
            ),
        );
    };

    const parsedCategories = useMemo(
        () => (reportCategories.length > 0 ? parseReportCategories(reportCategories) : []),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reportCategories],
    );

    useEffect(() => {
        setAllCategories(parsedCategories);
        setSearchResults(parsedCategories);
    }, [parsedCategories]);
    const isUserClientGuardingAdmin = useIsUserClientGuardingAdmin();

    return {
        tableData: searchResults,
        isLoading: fetchStatus === RequestStatus.loading,
        isEditable: isUserClientGuardingAdmin && isEditModeEnabled,
        onSeverityChange: () => {}, // TODO: Implement
        searchValue,
        setSearchValue,
        search,
        suggestions,
    };
};
